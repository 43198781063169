<template>
  <div class="dataset-table__values-list">
    <!--------------------------------------------
    |
    | EDIT OPTIONS
    |
    --------------------------------------------->
    <button
      class="table__toolbar-btn"
      @click="isEditingLabels = true"
      v-if="!hideOptions && isCategoryType"
    >
      <SvgIconDecorative icon="tag" />
      <VisibleText>Edit Labels</VisibleText>
    </button>

    <!--------------------------------------------
    |
    | VALUES
    |
    --------------------------------------------->
    <article
      :class="['dataset-table__values-list-section', { cat: isCategoryType }]"
    >
      <h2 class="dataset-table__values-list-header">
        <VisibleText>values</VisibleText>
      </h2>
      <ul class="dataset-table__values-list-ul">
        <li
          v-for="(value, index) in clientQuestion.unique_values"
          :key="`${clientQuestion._id.$oid}-vals-${index}`"
        >
          <DatasetTableValuesLablelInput
            :client-question="clientQuestion"
            :index="index"
            :value="value"
            val-type="val"
            @update-label="updateLabel"
            v-if="isEditingLabels && isCategoryType"
          >
            {{ value.value }}
          </DatasetTableValuesLablelInput>
          <span v-else>
            <span class="dataset-table__values-value">
              {{ value.value }}
            </span>
            <span class="dataset-table__values-label" v-show="isCategoryType">
              {{ value.label || "no label" }}
            </span>
          </span>
        </li>
      </ul>
    </article>

    <!--------------------------------------------
    |
    | SAVE OPTIONS
    |
    --------------------------------------------->
    <div
      class="dataset-table__values-list-save-options"
      v-show="isEditingLabels"
    >
      <button class="dataset-table__values-list-save-btn" @click="saveLabels">
        <SvgIconDecorative icon="check" />
        <VisibleText>Save Labels</VisibleText>
      </button>
      <button class="btn-cancel" @click="isEditingLabels = false">
        <SvgIconDecorative icon="remove" />
        <VisibleText>Cancel</VisibleText>
      </button>

      <span
        :class="[
          'dataset-table__values-msg',
          { 'success-msg__inline': message.success }
        ]"
      >
        {{ message.success }}
      </span>
      <span
        :class="[
          'dataset-table__values-msg',
          { 'error-msg__inline': message.err }
        ]"
      >
        {{ message.err }}
      </span>
    </div>
  </div>
</template>

<script>
// Components
import DatasetTableValuesLablelInput from "./DatasetTableValuesLablelInput.vue"
import SvgIconDecorative from "@/components/UI/Svg/SvgIconDecorative.vue"

// Mixins
import DataValuesMixin from "@/components/Dataset/DatasetTable/mixins/dataValuesMixin.js"

export default {
  name: "DatasetTableValuesList",
  mixins: [DataValuesMixin],
  components: {
    DatasetTableValuesLablelInput,
    SvgIconDecorative
  },
  props: {
    clientQuestion: {
      default: () => {},
      type: Object,
      required: true
    },
    hideOptions: {
      default: () => true,
      type: Boolean
    }
  },
  computed: {
    isCategoryType() {
      return (
        this.clientQuestion.approved_data_type &&
        this.clientQuestion.approved_data_type === "CAT"
      )
    }
  }
}
</script>
